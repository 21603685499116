<template>
    <div class="page-container">
        <div class="aboutme-cont">
            <div class="aboutme-img">
                <img :src="require('@/assets/images/henok.jpg')" alt="Henok">
            </div>
            <div class="context">
                <p v-for="bio in bios" :key="bio.id">
                    {{ bio.bio }}
                </p>
            </div>
        </div>
        <div class="my-skills">
            <h2>{{ clientsHeading }}</h2>
            <div class="clients-cont">
                <div class="clients">
                    <div v-for="client in clients" :key="client.id" class="client">
                        <img :src="client.img" :alt="client.title">
                        <p>{{ client.title }}</p>
                    </div>
                </div>
           </div>
       </div>
    </div>    
</template>
<script>
export default {
    
    data() {
        return {
            bios: [
            {
                id: 1, bio: "Hello! I'm Henok"
            },
            {
                id: 2, bio: "Hello! I'm Henok, a passionate and dedicated software developer with a strong foundation in various programming languages and technologies. I am currently pursuing a bachelor's degree in Computer Science, where I have honed my skills in Java, HTML, CSS, JavaScript, Python, and C#/.NET", },       
            {
                id: 3,
                bio: 'My expertise extends to software testing, Vue.js, Node.js, Git, and Java Android development. I have a solid understanding of object-oriented programming principles and have applied them in numerous projects.',
            },
            {
                id: 4,
                bio: 'I built this portfolio using Vue.js to showcase my skills and projects. I am always eager to learn new technologies and take on challenging projects that allow me to grow as a developer. Feel free to explore my portfolio and get in touch if you have any questions or opportunities to collaborate!',
            }
            ],
            clientsHeading: 'Some of my skills',
            clients: [
				{
					id: 1,
					title: 'Java',
					img: require('@/assets/images/java.jpg'),
				},
				{
					id: 2,
					title: 'Python',
					img: require('@/assets/images/python.jpg'),
				},
				{
					id: 3,
					title: 'Java Android development',
					img: require('@/assets/images/java_android.jpg'),
				},
				{
					id: 4,
					title: 'Software Testing',
					img: require('@/assets/images/software_testing.jpg'),
				},
				{
					id: 5,
					title: 'Front End development',
					img: require('@/assets/images/htmlcssjs.jpg'),
				},
				{
					id: 6,
					title: 'C#/.NET',
					img: require('@/assets/images/C#.jpg'),
				},
				{
					id: 7,
					title: 'NodeJs',
					img: require('@/assets/images/NodeJs.jpg'),
				},
				{
					id: 8,
					title: 'VueJS',
					img: require('@/assets/images/VueJs.png'),
				},
                {
					id: 9,
					title: 'ReactJS',
					img: require('@/assets/images/reactJS.png'),
				},
			],
            
        };
    },
};
</script>

<style scoped>
.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #07090cfb;
    padding: 2.5rem 1rem; 
}

.aboutme-cont {
    display: flex;
    align-items: flex-start;
    gap: 2.5rem; 
    padding: 2.5rem 1rem; 
    background-color: #13181ffb;
    border-radius: 0.75rem; 
    width: 100%;
    max-width: 1600px;
    margin-top: 2.5rem; 
}

.aboutme-img {
    flex-shrink: 0;
    width: 100%;
    max-width: 30%;
}

.aboutme-img img {
    width: 100%;
    border-radius: 0.75rem; 
    object-fit: cover;
}

.context {
    width: 100%;
    max-width: 75%; 
    text-align: left; 
    color: #fff;
}

.context p {
    font-family: var(--font-general-regular); /* Assuming a custom font-family */
    margin-bottom: 1rem; 
    color: #d1d5db; 
    font-size: 1.8rem; 
    padding-block: 8px;
}

.my-skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1600px;
    margin-top: 3.5rem; /* Equivalent to mt-10 in Tailwind */
}

.my-skills h2 {
    color: #fff;
    font-size: 3rem;
}

.clients-cont {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2.5rem 1rem;
    background-color: #07090cfb;
    border-radius: 0.75rem;
}

.clients {
    display: grid;
    grid-template-columns: repeat(4, 2fr);
    gap: 1.5rem; 
    width: 100%;
    max-width: 1600px;
}

.client {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client img {
    width: 100%;
    height: 170px;
    border-radius: 0.75rem; /* Equivalent to rounded-xl in Tailwind */
    object-fit: cover;
}

.client p {
    margin-top: 0.5rem; 
    color: #d1d5db;
    font-size: 2rem; 
}

@media (max-width: 1024px) {

    .context p {
        font-size: 1.2rem; 
    }
}

@media (max-width: 768px) {
    .context p {
        font-size: 1rem; 
    }

    .clients {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(2, 2fr);
        gap: 1.5rem; /* Equivalent to sm:gap-6 in Tailwind */
    }

}

@media (max-width: 600px) {
    .context p {
        font-size: 1rem; 
        padding-block: 10px;
    }
    .aboutme-cont {
        margin-top: -2rem; /* Equivalent to sm:mt-20 in Tailwind */
    }

    .clients {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: 1fr;
        gap: 1rem; /* Equivalent to sm:gap-6 in Tailwind */
    }

    .my-skills h2 {
        font-size: 1.7rem
    }
   
}
</style>
