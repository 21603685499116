<template>
    <nav class="navbar">
        <!-- Logo -->
        <div class="logo">              
            <a href="/">
                <h3><img src="@/assets/images/heni.png"  alt="H icon" class="logo-icon"> Henok</h3>
            </a>    
        </div>

        <!-- NAVIGATION MENU-->
        <ul class="nav-links">

            <!--Using checkbox-->
            <input type="checkbox" id="checkbox_toggle" />
            <label for="checkbox_toggle" class="hamburger">&#9776;</label>

            <!-- NAVIGATION MENUS-->
            <div class="menu">
                <li><router-link to="/">Home</router-link></li>
                <li><router-link to="/projects">Projects</router-link></li>
                <li><router-link to="./aboutMe">About Me</router-link></li> 
                <li><router-link to="/contact">Contact</router-link></li>
            </div>             
        </ul>   
    </nav>
        <!-- Footer section added -->
    
  <div class="view-link" id="view-link">
    <router-view></router-view>
  </div>

  <div>
    <AppFooter />
  </div>
  
</template>

<script>
import AppFooter from './components/AppFooter.vue';
//  background-color: #152136fb;

export default {
    name: 'App',
    components: {
        AppFooter,
    }
}
</script>

<style scoped>
html {

}
*, *:before, *:after {
      box-sizing: inherit;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;          
}

body {
    margin: 0;
    padding: 0;
    background-color: #111111;
    min-height: 100vh; 
    width: 100%;   
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

/* STYLING NAVBAR STARTS*/
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    /* background-color: #152136fb;*/
    background-color: #07090cfb;
    color: #fff;
    width: 100%;
     
}

/* STYLING LOGO BEGINS*/
.logo h3 {
    color: #fff;
    font-size: 35px;
    transition: .5s ease;
    display: flex;
    align-items: center;
    gap: 0.8rem;
 
}

.logo, .nav-links {
    margin-top: 20px;
}

.logo .logo-icon {
  margin-left: 0; /* Adjust the spacing as needed */
  font-size: 35px; /* Make sure the icon size matches the text */
  width: 40px;
  font-weight: bold;
  border-radius: 8px;
  background-color: #fff;
}

.nav-links {
    display: flex;
    align-items: center;
}

.nav-links a {
    color: #fff;
    font-size: 2rem;
}
/* NAVBAR MENU*/
.menu {
    display: flex;
    gap: 1.5rem;
    font-size: 1.3rem;
    padding-right: 2rem;
}

.menu li:hover {
    background-color: #4c9e9e;
    border-radius: 5px;
    transition: .3s ease;
}


.menu li {
    padding: 5px 14px;
}

/* RESPONSIVE NAVBAR MENU STARTS */

/* CHECKBOX HACK */
input[type="checkbox"] {
    display: none;
}

.hamburger {
    display: none;
    font-size: 2rem;
    user-select: none;
    position: relative;
    z-index: 20;
}

.view-link {
    width: 100%;
    background-color:  #07090cfb;
    min-height: 100vh; /* Add this line */    
}

@media (max-width: 1024px) {

    .nav-links a {
        font-size: 1.4rem;
    }

    .logo h3 {
        font-size: 1.4rem;
    }
}

/* APPLYING MEDIA QUERIES */
@media (max-width: 768px) {
    .navbar {
        display: flex;
        justify-content: space-between;
    }


    .menu {
        display: none;
        position: absolute;
        top: 75px; /* Adjust to prevent overlap with the logo and hamburger */  
        right: 0;
        left: 0;
        background-color: teal;
        text-align: center;
        padding: 16px;
        z-index: 10;
    }

    .menu li {
        width: 100%;
    }

    .menu li:hover {
        display: inline-block;
        background-color:#4c9e9e;
        transition: 0.3s ease;
    }

    input[type=checkbox]:checked ~ .menu {
        display: block;
        flex-direction: column;
      }
    
    .hamburger {
    display: block; 
    }

  }

  /* APPLYING MEDIA QUERIES */
@media (max-width: 600px) {
    .navbar {
        display: flex;
        justify-content: space-between;
    }


    .menu {
        display: none;
        position: absolute;
        top: 75px; /* Adjust to prevent overlap with the logo and hamburger */  
        right: 0;
        left: 0;
        background-color: teal;
        text-align: center;
        padding: 16px;
        z-index: 10;
    }

    .menu li {
        width: 100%;
    }

    .menu li:hover {
        display: inline-block;
        background-color:#4c9e9e;
        transition: 0.3s ease;
    }

    input[type=checkbox]:checked ~ .menu {
        display: block;
        flex-direction: column;
      }
    
    .hamburger {
    display: block; 
    }

  }

</style>

