<script>
import ProjectSet from '../components/ProjectSet.vue';

export default {
    name: 'HomePage',
    components: {
        ProjectSet
    }
}
</script>

<template>
  <section>
		<!-- Banner left contents -->
		<div class="banner-left">
			<h1 class="banner-title">
				Hi, I am Henok
			</h1>
			<p class="banner-text">
				A Full-Stack Developer & Design Enthusiast
			</p>
      <div class="cv-download">
        
        <a class="download-button" href="/files/My-Resume.pdf" download="My-Resume.pdf"> <font-awesome-icon class="arrow" icon="fa-circle-arrow-down" />
        Download CV
           <!-- Change the class to match the Font Awesome version you're using -->
        </a>
      </div>
    </div>
    <div class="banner-right">
        <img
          src="@/assets/images/developer.svg"
          alt="Developer"
        />
		</div>	
  </section>

  <div>
    <ProjectSet />
  </div>
  <!-- PROJECT PART-->

</template>

<style scopped>

section {
  display: flex;
  flex-direction: row;
  align-items: center; /* Ensure vertical alignment */
  justify-content: center; /* Space between the left and right sections */
  gap: 2vw; /* Use viewport width for dynamic spacing */
  max-width: 1700px;
  margin: 0 auto; /* Centers the container */
  padding: 0 20px;
}

.banner-left, .banner-right {
  flex: 1 1 50%; /* Each takes half of the section */
  padding: 1vw;
  display: flex;
  justify-content: center; /* Center the contents horizontally */
  align-items: center;
   
}

.banner-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  
}

.banner-right img {
  max-width: 100%; /* Ensure the image takes the full width of its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
}

.banner-title {
  font-size: 3vw; /* Responsive font size */
  color: #fff;
  margin-bottom: 1rem;
}

.banner-text {
  font-size: 1.5vw;
  color: #fff;
  margin-bottom: 1rem;
 
}

.cv-download {
  background-color: #fff;
  font-size: 1vw;
  border-radius: 5px;
  padding: 0.5vw;
  transition: all 0.3s ease; /* Smooth transition for all properties */ 
  
}

.download-button {
  width: auto;
  min-width: 180px; /* Minimum width to prevent too much shrinking */
  padding: 0.5vw 0.5vw; /* Responsive padding */
  font-size: 30px;
  white-space: nowrap; /* Prevent wrapping of text inside the button */
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  color: black;
  text-decoration: none;
}

.cv-download:hover {
  background: #5050ce; 
}

.download-button:hover {
  /* Blue background on hover */
  color: white; /* White text on hover */
  text-decoration: none;
}


/* Responsive adjustments */
@media (max-width: 1024px) {

.banner-right img {
  max-width: 100%; /* Ensure the image takes the full width of its container */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
}


  .banner-title {
    font-size: 4vw; /* Larger font size for smaller devices */
  }

  .banner-text {
    font-size: 2vw;
  }

  .cv-download {
    font-size: 2.5vw;
  }

  .download-button {
    padding: 0.5vw 0.5vw;
    font-size: 1.4rem;
  }
}


/* Further responsive settings for smaller devices */
@media (max-width: 768px)   {
  section {
    flex-direction: column;
  }

  .banner-right img {
    max-width: 100%; /* Ensure the image takes the full width of its container */
    height: 600px; /* Maintain aspect ratio */
    width: 100%; 
    object-fit: cover;
  }

  .cv-download { 
    margin-left: 110px; 
    padding: 0.5rem; 
  }


  .banner-title {
    font-size: 7vw; /* Even larger font size for small devices */
    margin-left: auto; /* Center alignment if necessary */
    margin-right: auto;
  }
  .banner-text {
    font-size: 3vw;
    text-align: center;
  }
 
}

@media (min-width: 601px) and (max-width: 800px) {

  .banner-right img {
    max-width: 100%; 
    height: 400px; 
    width: 100%;  
    object-fit: cover;
  }
 
  .cv-download {
    font-size: 1vw;
    margin-left: 90px;  
    padding: 0.5vw;  
  }

  .download-button {
    padding: 0.1vw;
    font-size: 1.5rem;
  }

  .banner-title {
    font-size: 7vw;
  }
  .banner-text {
    font-size: 3vw;
    color: #fff;
    margin-bottom: 1rem;
  }
}

@media (max-width: 600px) {
  .banner-right img {
    max-width: 100%; /* Ensure the image takes the full width of its container */
    /* Maintain aspect ratio */
    width: 100%; 
    object-fit: cover;
  }
 
  .download-button {
    width: 70%;
    min-width: 180px;
    font-size: 20px;
  }

  .cv-download {
    font-size: 2.5vw;
    margin-left: 99px;    
  }

  .download-button {
    padding: 0.8vw;
  }

  .banner-title {
    font-size: 8vw;
    margin-left: 25px;
  }

  .banner-text {
    font-size: 8vw;
    margin-left: 25px;
  }

  .banner-text {
    font-size: 3vw;
    color: #fff;
    margin-bottom: 1rem;
  }
}

@media (max-width: 486px) {
  .banner-right img {
    max-width: 100%; /* Ensure the image takes the full width of its container */
    /* Maintain aspect ratio */
    width: 100%; 
    object-fit: cover;
  }
 
  .download-button {
    width: 70%;
    min-width: 180px;
    font-size: 20px;
  }

  .cv-download {
    font-size: 2.5vw;
    margin-left: 70px;    
  }

  .download-button {
    padding: 0.8vw;
  }

  .banner-title {
    font-size: 8vw;
    margin-left: 25px;
  }

  .banner-text {
    font-size: 8rem;
    margin-left: 25px;
  }

  .banner-text {
    font-size: 3vw;
    color: #fff;
    margin-bottom: 1rem;
  }
}
</style>