<template>
  
  <div class="skills-container">
      <a href="/webDev" class="skill-card">
          <img src="../assets/images/webdev.webp" v-on:click="navigate" alt="webdev">
          <h3>Web Development</h3>
      </a>
      <a href="/machine-learning" class="project-card">
          <img src="../assets/images/machinelearning.jpg" v-on:click="navigate" alt="Machine-lear">
          <h3>Machine Learning</h3>
      </a>
      <a href="/mobiledev" class="project-card">
          <img src="../assets/images/mobileApp.webp" v-on:click="navigate" alt="mobile">
          <h3>Mobile Development</h3>
      </a>
      <a href="/game-dev" class="project-card">
          <img src="../assets/images/gamedev.jpg" v-on:click="navigate" alt="Game-dev">
          <h3>Game Development</h3>
      </a>
      <a href="/soft-testing" class="project-card">
          <img  src="../assets/images/softwareTesting.jpg" v-on:click="navigate" alt="webdev">
          <h3>Software Testing</h3>
      </a>

    <!-- More project cards -->
    </div>
</template>

<script>

export default {
  name: 'SkillSet'
}
 
</script>

<style scooped>

.skills-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* This creates three columns */
    gap: 20px; /* Adjust spacing between cards as needed */
    padding: 20px; /* Padding around the entire grid */
    justify-content: center; /* Center the grid within its container */
    align-items: center; /* Align grid items vertically */
    border-bottom: 1px solid #888;
}

.skill-card {
    text-decoration: none;
    color: inherit;
    background: #445574fb;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;  
}

.skill-card img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
}

.skill-card h3, .skill-card p {
    padding: 0 20px;
    color: white;
}

.skill-card h3 {
    margin-top: 10px;
    font-size: 2rem;
}


.skill-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}



</style>