<template>
    <div class="recipe-container">
      <div class="recipe-title">
        <h2>Stuff Lending System</h2>
      </div>
      <div class="line-above"></div>
      <div class="recipes-container">
        <div class="recipe-card">
          <h3></h3>
          <img src="@/assets/images/stuffMenu.png" alt="Google Health Platform">
        </div>
        <div class="recipe-card">  
          <h3></h3>
          <img src="@/assets/images/stuffMenu.png" alt="Phoenix Digital Agency">
        </div>
        <div class="recipe-card">
          <h3></h3>
          <img src="@/assets/images/stuffMenu.png" alt="Google Health Platform">
        </div>
      </div>
      
      <div class="recipe-description">
        <p>The Stuff Lending System is an innovative console application designed to promote sustainable living by enabling users to lend and borrow items within a community. This system aims to reduce the need for purchasing rarely used items, thereby minimizing waste and encouraging resource sharing among friends, coworkers, and neighbors. The Stuff Lending System serves as a proof of concept for a community-based lending platform, showcasing a robust design and implementation that can be expanded and refined. This project highlights my ability to design and develop software adhering to industry-standard architectural patterns and principles, while delivering functional and well-documented code.
           This system is a testament to my skills in Object-Oriented Design, Java programming, and the ability to create maintainable and scalable software solutions.</p>
      </div>
      <div class="line-below"></div>
    </div>
  </template>
  
  <script>
    export default {
        name: 'StuffLendingSystem'
    }
  
  </script>
  
  
  <style scoped>
   @import './styles/projectDescription-styles.css';
  </style>
  