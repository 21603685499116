import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import App from './App.vue'

library.add(fas)

import HomePage from './components/HomePage.vue'
import SkillSet from './components/SkillSet.vue'
import ProjectSet from './components/ProjectSet.vue'
import ContactSet from './components/ContactSet.vue'
import WebbDev from './components/ProjectDescription/WebbDev.vue'
import AboutMe from './components/about/AboutMe.vue'
import HotelManagementSystem from './components/ProjectDescription/HotelManagementSystem.vue'
import RecipeApp from './components/ProjectDescription/RecipeApp.vue'
import QuizApp from './components/ProjectDescription/QuizApp.vue'
import StuffLendingSystem from './components/ProjectDescription/StuffLendingSystem.vue'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomePage },
    { path: '/skills', component: SkillSet },
    { path: '/projects', component: ProjectSet },
    { path: '/contact', component: ContactSet },
    { path: '/webDev', component: WebbDev },  
    {path: '/aboutMe', component: AboutMe},
    {path: '/HotelManagementSystem', component: HotelManagementSystem},
    {path: '/RecipeApp', component: RecipeApp},
    {path: '/QuizApp', component: QuizApp},
    {path: '/StuffLendingSystem', component: StuffLendingSystem},
  ]
})

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)

app.mount("#app")