<template>
  <div class="contact-container">
    <div class="contact-form">
      <h2>Contact Form</h2>
      <form>
        <div class="form-group">
          <label for="fullname">Full Name</label>
          <input class="input" type="text" id="fullname" placeholder="Full Name" name="fullname"/>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input class="input" type="text" id="email" placeholder="Email" name="email"/>
        </div>
        <div class="form-group">
          <label for="subject">Subject</label>
          <input class="input" type="text" id="subject" placeholder="Subject" name="subject"/>
        </div>
        <div class="form-group">
          <label for="message">Message</label>
          <textarea class="input-textarea" id="message" placeholder="Message" name="message"></textarea>
        </div>
        <button>Send message</button>
      </form>
    </div>
    <div class="contact-details">
      <h2>Contact Details</h2>
      <ul class="contacts-ul">
        <li>
          <a>
          <img class="icon" src="../assets/feather/map-pin.svg" alt="address">Vallingby, Stockholm, Sweden
        </a>
        </li>
        <li>
          <a href="mailto:henokrezene95@gmail.com?subject=Contact%20from%20Website&body=Please%20enter%20your%20message%20here">
          <img class="icon" src="../assets/feather/mail.svg" alt="address"> henokrezene95@gmail.com
        </a>
        </li>
        <li>
          
        <a href="tel:+46729495264">
          <img class="icon" src="../assets/feather/phone.svg" alt="address">+467 294 952 64
        </a>
          
        </li>
      </ul>

      </div>    
  </div>  
</template>

<style scoped>
  .contact-container {
    display: flex;
    justify-content:center;
    align-items: flex-start;
    gap: 2rem;
    
  }

  .contact-form {
    background-color: #13181ffb;
    width: 500px;
    margin: 4rem 0;
    color: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-sizing: border-box;
  }

  .contact-details {
    width: 500px;
    margin: 4rem 0;
    color: #fff;
    border-radius: 8px;
    padding: 2rem;
    box-sizing: border-box;
  }

  .contact-form h2, .contact-details h2 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }

  .input, .input-textarea {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    color: #fff;
    background-color: #3b4c6bfb;
    font-size: 1.3em;
  }

  .input::placeholder, .input-textarea::placeholder {
    color: #bbb;
  }

  button {
    font-size: 1.3em;
    background-color: #4a90e2;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  button:hover {
    background-color: #357ab8;
  }

  .contact-details {
    padding: 0;
  }
  
  .contact-details .contacts-ul {
    list-style: none;
    padding: 0;
  }

  .contact-details li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }

  .contact-details a {
    color: #fff;
    text-decoration: none;
  }

  .contact-details a:hover {
    text-decoration: underline;
  }

  .icon {
    margin-right: 1rem;
    color: white;
    filter: invert(100%);
  }
  
  @media (max-width: 1024px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
    }

    .contact-details {
      order: -1; /* Order first on small screens */
  
    }

    .contact-form {
      order: 0; /* Order second on small screens */
      width: 100%; /* Ensure it takes full width on small screens */
      margin: 1rem 0;
    }
    .contact-form, .contact-details {
      width: 500px;
      margin: 1rem 0;
    }

    .contact-details h2, .contact-details .contacts-ul{
      margin: 1rem 0;
    }

    label {
    font-size: 1rem;
  }

    .input, .input-textarea {
    font-size: 1em;
    padding: 0.6rem;
  }

  .contact-form h2, .contact-details h2 {
    font-size: 1.5rem;
  }
   .contact-details li {
    font-size: 1em;
    
   }   

  }

  @media (max-width: 768px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
    }

    .contact-details {
      order: -1; /* Order first on small screens */
  
    }

    .contact-form {
      order: 0; /* Order second on small screens */
      width: 100%; /* Ensure it takes full width on small screens */
      margin: 1rem 0;
    }
    .contact-form, .contact-details {
      width: 500px;
      margin: 1rem 0;
    }

    .contact-details h2, .contact-details .contacts-ul{
      margin: 1rem 0;
    }

    label {
    font-size: 1rem;
  }

    .input, .input-textarea {
    font-size: 1em;
    padding: 0.6rem;
  }

  .contact-form h2, .contact-details h2 {
    font-size: 1.5rem;
  }
   .contact-details li {
    font-size: 1em;
    
  }
  }

  @media (max-width: 600px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
    }

    .contact-details {
      order: -1; /* Order first on small screens */
  
    }

    .contact-form {
      order: 0; /* Order second on small screens */
      width: 100%; /* Ensure it takes full width on small screens */
      margin: 1rem 0;
    }
    .contact-form, .contact-details {
      width: 400px;
      margin: 1rem 0;
    }

    .contact-details h2, .contact-details .contacts-ul{
      margin: 1rem 0;
    }

    label {
    font-size: 0.9rem;
  }

    .input, .input-textarea {
    font-size: 0.9em;
    padding: 0.6rem;
  }

  .contact-form h2, .contact-details h2 {
    font-size: 1.3rem;
  }
   .contact-details li {
    font-size: 1em;
    
  }
  }

  @media (max-width: 448px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
    }

    .contact-details {
      order: -1; /* Order first on small screens */
  
    }

    .contact-form {
      order: 0; /* Order second on small screens */
      width: 100%; /* Ensure it takes full width on small screens */
      margin: 1rem 0;
    }
    .contact-form, .contact-details {
      width: 350px;
      margin: 1rem 0;
    }

    .contact-details h2, .contact-details .contacts-ul{
      margin: 1rem 0;
    }

    label {
    font-size: 1rem;
  }

    .input, .input-textarea {
    font-size: 0.9em;
    padding: 0.6rem;
  }

  .contact-form h2, .contact-details h2 {
    font-size: 1.3rem;
  }
   .contact-details li {
    font-size: 1em;
    
  }
  }
  
</style>