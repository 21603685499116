<script>
export default {
  name: 'ProjectSet',
  data() {
    return {
      searchQuery: '',
      showSuggestions: false,
      availableProjects: [
        { name: 'Digital Portfolio', path: '/webDev' },
        { name: 'Hotel Management System', path: '/HotelManagementSystem' },
        { name: 'Recipe Application', path: '/RecipeApp' },
        { name: 'Quiz App', path: '/QuizApp' },
        { name: 'Stuff Lending System', path: '/StuffLendingSystem' }
        // Add more project titles and paths as needed
      ],
      filteredProjects: []
    };
  },
  methods: {
    handleSearch() {
      // Logic for handling the search submit, if needed
    },
    filterProjects() {
      this.filteredProjects = this.availableProjects.filter(project =>
        project.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    hideSuggestions() {
      // Delay hiding to allow clicking on suggestions
      setTimeout(() => {
        this.showSuggestions = false;
      }, 200);
    },
    clearSearch() {
    this.searchQuery = '';
    this.showSuggestions = false;
   }
  }, 
  watch: {
    $route() {
      this.clearSearch();
    }
  }
};
</script>

<template>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <div class="project-container">
    <div class="title-text">
      <h2>Projects Portfolio</h2>
      <p>Search projects by title or filter by category</p>
    </div>
    
    <div class="search-or-filter-project">
      <div class="searchBtn-and-input">
        <form id="form" @submit.prevent="handleSearch">
          <button class=searchBtn type="submit"><i class="fa fa-search"></i></button>
          <input 
            class="inputSearch" 
            type="text" 
            placeholder="Search Projects" 
            v-model="searchQuery" 
            @focus="showSuggestions = true" 
            @blur="hideSuggestions"
            @input="filterProjects"
          >
          <ul v-if="showSuggestions && filteredProjects.length" class="suggestions-list">
            <li v-for="project in filteredProjects" :key="project.name">
              <a :href="project.path" @click="clearSearch">{{ project.name }}</a>
            </li>
          </ul>

        </form>    
      </div>
      <div class="dropDown-menu">
        <select name="project-names" id="project-names">
          <option class="all-proj" value="all-projects">All projects</option>
          <option value="webb-application">Web Application</option>
          <option value="front-end">Java Console App</option>
          <option value="design">Desktop App</option>
          <option value="design">Vue Js App</option>
          
        </select>
      </div>
    </div>
    <div class="line-above"></div>
    <div class="projects-container">
      <a href="/webDev" class="project-card">
          <img src="../assets/images/main-port-page.png" v-on:click="navigate" alt="Google Health Platform">
          <h3>Digital Portfolio</h3>
          <p>Web Application</p>
      </a>
      <a href="/HotelManagementSystem" class="project-card">
          <img src="../assets/images/hotelAddCustomer.png" v-on:click="navigate" alt="Phoenix Digital Agency">
          <h3>Hotel Management System</h3>
          <p>Desktop Application</p>
      </a>
      <a href="/RecipeApp" class="project-card">
          <img src="../assets/images/RecipeApp.png" v-on:click="navigate"  alt="Recipe App">
          <h3>Recipe Application</h3>
          <p>web Application</p>
      </a>
      <a href="/QuizApp" class="project-card">
          <img src="../assets/images/quizApp.png" v-on:click="navigate" alt="Quiz App">
          <h3>Quiz App</h3>
          <p>Web Application</p>
      </a>
      <a href="StuffLendingSystem" class="project-card">
          <img src="../assets/images/stufflending.png" alt="Google Health Platform">
          <h3>Stuff Lending System</h3>
          <p>Java console App</p>
      </a>
      
    <!-- More project cards -->
    </div>
    <div class="line-below"></div>
  </div>
 
</template>

<style scopped> 

.project-container {
  max-width: 1700px;
  margin: 0 auto; /* Centers the container */
  padding: 50px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title-text {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family:Arial, Helvetica, sans-serif;
}

.title-text h2 {
  font-size: 3rem; 
  font-weight: bold; 
}

.title-text p {
  font-size: 1.7rem;
  padding-top: 10px;
}

/* form styling*/
.search-or-filter-project {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;  
  /*font-size: 25px;*/
}

.searchBtn-and-input form {
  display: flex;
  align-items: center; /* Ensure the button and input are aligned vertically */
  gap: 4px;
}

.searchBtn-and-input form::placeholder {
  color: #fff;
}

.searchBtn-and-input form .searchBtn{
  border: none;
  border-radius: 6px;
  padding: 8px;
  background-color:#445574fb; 
  color: #fff;
  cursor: pointer;
}

.searchBtn-and-input .inputSearch {
  border: none;
  border-radius: 6px;
  padding: 8px;
  background-color: #445574;
  color: #fff;
  width: 100%;
}

form input::placeholder {
  color: white;
}

/* DROP DOWN STYLING*/
.dropDown-menu select {
  border: none;
  border-radius: 6px;
  padding: 8px;
  background-color:#445574fb;
  color: #fff;
  cursor: pointer;
}

.line-above {
  border-bottom: 1px solid #888;
  margin-bottom: 10px;
  margin-top: 10px;
}

.projects-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* This creates three columns */
    gap: 20px; /* Adjust spacing between cards as needed */   
    align-items: start; /* Align grid items vertically */   
}

.project-card {
    background-color: #152136fb;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);  
    border-radius: 8px;
    overflow: hidden;
    text-decoration: none;
    color: white;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;  
}

.project-card img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.project-card h3, .project-card p {
    padding: 0 20px;
}

.project-card h3 {
    margin-top: 10px;
    font-size: 2rem;
}

.project-card p {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.line-below {
  border-bottom: 1px solid #888;
  margin-top: 20px;
}

.project-card:hover {
  text-decoration: none;
  color: #fff;
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #fff;
  position: absolute;
  z-index: 1;
  width: calc(100% - 20px); /* Adjust width to fit within the input box */
  max-height: 200px;
  overflow-y: auto;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li a {
  text-decoration: none;
  color: black;
  display: block;
}

.suggestions-list li:hover {
  background-color: #eee;
}

.searchBtn-and-input {
  position: relative; /* Make the container position relative to contain the absolute suggestions list */
}

.inputSearch {
  width: 100%; /* Ensure the input takes the full width of its container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


/* Further responsive styles */
@media (max-width: 1024px) and (min-width: 769px)   {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .title-text p {
    font-size: 1.3rem;
  }

  .searchBtn-and-input form{
    font-size: 1rem;
  }

  .dropDown-menu {
    font-size: 1rem;
  }

  .search-or-filter-project {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns for better utilization of space */
  }

  .title-text h2 {
    font-size: 2.5rem;
  }

  .title-text p {
    font-size: 1.2rem;
  }

  .search-or-filter-project {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    gap: 10px; 
    width: 100%;
  }

  .searchBtn-and-input,
  .dropDown-menu {
    width: 100%; 
  }

  .searchBtn-and-input form,
  .dropDown-menu select {
    width: 100%; 
  }

  .projects-container .project-card h3 {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .projects-container {
    grid-template-columns: 1fr;
  }

  .title-text h2 {
    font-size: 1.5rem;
  }

  .title-text p {
    font-size: 1.5rem;
  }

  .search-or-filter-project {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    gap: 5px; 
    width: 100%;
  }

  .searchBtn-and-input form,
  .dropDown-menu select {
    font-size: 0.9rem;
    width: 100%; 
  }

  .searchBtn-and-input {
    margin-bottom: 8px;
    width: 100%;
  }

  .projects-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 460px) {
  .title-text h2 {
    font-size: 1.2rem;
  }

  .title-text p {
    font-size: 0.9rem; 
  }

  .search-or-filter-project {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center elements horizontally */
    gap: 10px; /* Space between elements */
    width: 100%;
  }

  .searchBtn-and-input form,
  .dropDown-menu select {
    font-size: 0.9rem;
    width: 100%; /* Ensure form and dropdown menu are full width */
  }

  .searchBtn-and-input {
    margin-bottom: 10px;
    width: 100%;
  }

  .dropDown-menu {
    font-size: 0.9rem;
    margin-bottom: 10px;
    width: 100%;
  }
}

</style>