<template>
    <div class="recipe-container">
      <div class="recipe-title">
        <h2>Recipe Application</h2>
      </div>
      <div class="line-above"></div>
      <div class="recipes-container">
        <div class="recipe-card">
          <h3>Home page</h3>
          <img src="@/assets/images/RecipeApp.png" alt="Google Health Platform">
        </div>
        <div class="recipe-card">  
          <h3>Random Recipe</h3>
          <img src="@/assets/images/RecipeApp.png" alt="Phoenix Digital Agency">
        </div>
        <div class="recipe-card">
          <h3>Favorizte Meals</h3>
          <img src="@/assets/images/RecipeApp.png" alt="Google Health Platform">
        </div>
      </div>
      
      <div class="recipe-description">
        <p>This recipe application dynamically generates random recipes for users to explore new meal ideas. Users can search for recipes by name, mark their favorite recipes, and save them for easy access later. The app utilizes the Fetch API to retrieve recipe data and employs local storage to maintain user preferences and favorites. This intuitive and interactive app offers a seamless experience for discovering and managing recipes.</p>
      </div>
      <div class="line-below"></div>
    </div>
   
  </template>
  
<script>
    export default {
        name: 'RecipeApp'
    }

</script>


<style scoped>
  @import './styles/projectDescription-styles.css';
</style>
  