<template>
    <div class="recipe-container">
      <div class="recipe-title">
        <h2>Hotel Management System</h2>
      </div>
      <div class="line-above"></div>
      <div class="recipes-container">
        <div class="recipe-card">
          <h3>Home page</h3>
          <img src="@/assets/images/hotelManagementSystem.png" alt="Google Health Platform">
        </div>
        <div class="recipe-card">  
          <h3>Menu</h3>
          <img src="@/assets/images/booking-menu.png" alt="Phoenix Digital Agency">
        </div>
        <div class="recipe-card">
          <h3>Booking Confirmation</h3>
          <img src="@/assets/images/confirmBooking.png" alt="Google Health Platform">
        </div>
      </div>
      
      <div class="recipe-description">
        <p>This desktop application is a Hotel Booking System created using JavaFX and MySQL database connection. The system is designed to manage a hotel with about 20 to 80 rooms, and it features different functionalities for reception staff and administrators.
           Users can set and change their screen names and passwords. Administrators can add, edit, and delete rooms with details such as size, beds, number, and location. They can also add users as either reception staff or administrators. Reception staff can view room details, manage bookings, search for bookings, and mark bookings as paid. They can also create and update customer details.
           Key functionalities include the ability to start the application and see a welcome screen, set and change screen names and passwords, and for administrators to manage rooms and users. Reception staff can view room details, see all bookings for specific days or rooms, book rooms for specific date ranges, see an overview of bookings, change bookings, mark bookings as paid, create and update customer details, search for bookings, and search for available free dates for rooms.
           This project ensures flexibility and efficiency in managing hotel operations, making it a robust solution for hotel management.</p>
      </div>
      <div class="line-below"></div>
    </div>
   
  </template>
  
<script>
    export default {
        name: 'HotelManagementSystem'
    }

</script>


  <style scoped>
  @import './styles/projectDescription-styles.css';
  </style>
  