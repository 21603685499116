<template>
  <div class="recipe-container">
    <div class="recipe-title">
      <h2>Quiz Application</h2>
    </div>
    <div class="line-above"></div>
    <div class="recipes-container">
      <div class="recipe-card">
        <h3>Home page</h3>
        <img src="@/assets/images/quiz-welcome.png" alt="Google Health Platform">
      </div>
      <div class="recipe-card">  
        <h3>Question</h3>
        <img src="@/assets/images/quiz-question.png" alt="Phoenix Digital Agency">
      </div>
      <div class="recipe-card">
        <h3>Quiz Completed</h3>
        <img src="@/assets/images/quiz-complete.png" alt="Google Health Platform">
      </div>
    </div>
    
    <div class="recipe-description">
      <p>I developed a responsive quiz game application using JavaScript to demonstrate proficiency in modern web development techniques. The application fetches quiz questions from an external server using the Fetch API and posts users' answers back for validation. This project highlights my ability to handle asynchronous communication, organize code using ES6+ features like classes and modules, and interact with RESTful APIs. I implemented robust error handling and effective state management to enhance user experience. Technologies used include JavaScript (ES6+), Fetch API, HTML5, and CSS3.</p>
    </div>
    <div class="line-below"></div>
  </div>
</template>

<script>
  export default {
      name: 'QuizApp'
  }

</script>

<style scoped>
 @import './styles/projectDescription-styles.css';
</style>
