<template>
  <div class="recipe-container">
    <div class="recipe-title">
      <h2>Digital Portfolio</h2>
    </div>
    <div class="line-above"></div>
    <div class="recipes-container">
      <div class="recipe-card">
        <h3>Home page</h3>
        <img src="@/assets/images/portf-homepage.png" alt="Google Health Platform">
      </div>
      <div class="recipe-card">  
        <h3>Projects</h3>
        <img src="@/assets/images/portf-projectsPage.png" alt="Phoenix Digital Agency">
      </div>
      <div class="recipe-card">
        <h3>About Me</h3>
        <img src="@/assets/images/aboutme.png" alt="Google Health Platform">
      </div>
    </div>
    
    <div class="recipe-description">
      <p>I created a digital portfolio using Vue.js to showcase my skills and projects, demonstrating my design capabilities and technical proficiency. The portfolio features a clean, responsive design that adapts seamlessly to various devices, ensuring an optimal user experience across desktops, tablets, and mobile phones. It includes sections for project listings, detailed project descriptions, This project demonstrates my ability to design and develop a professional portfolio website, utilizing modern web technologies and best practices in responsive design</p>
    </div>
    <div class="line-below"></div>
  </div>
</template>

<script>
  export default {
      name: 'WebDev'
  }

</script>

<style scoped>
 @import './styles/projectDescription-styles.css';
</style>
